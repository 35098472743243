<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h2>Listado de convenios creados</h2>
      </v-card-title>
      <v-card-actions>
        <v-btn color="primary" @click=" $router.push({name: 'cm-creacion-convenio'})">
          Nuevo convenio
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <v-text-field
            v-model="proveedor"
            label="Buscar convenios por proveedor"
            @change="cargarConvenios"
            clearable
            @keyup="filterName"
            @click:clear="(proveedor = null), cargarConvenios()"
            outlined
        ></v-text-field>
        <DataTableComponent 
          :items="listado.data" 
          :headers="headers" 
          :total_registros="total"
          @paginar="paginar"
          :show_loading="listado.isLoading"
        >
          <template v-slot:[`item.fecha`]={item}>
            {{ moment(item.fecha).format('DD-MM-YYYY') + ' a las ' + moment(item.fecha).format('HH:MM') }}
          </template>
          <template v-slot:[`item.acciones`]={item}>
            <v-btn style="min-width: unset !important;" class="px-0 py-0" text @click="detalle(item.id)">
              <v-icon>mdi-archive-eye</v-icon>
            </v-btn>
            <v-btn v-if="item.pdf" style="min-width: unset !important;" class="px-0 py-0 ml-2" text @click="showPdf(item)">
              <v-icon>mdi-file-pdf-box</v-icon>
            </v-btn>
          </template>
        </DataTableComponent>
        <v-dialog
            v-model="dialog"
            transition="dialog-transition"
        >
          <detalle :id="id_detalle" @cerrar="dialog=false" v-if="dialog"/>
        </v-dialog>
      </v-card-text>
    </v-card>
    <PdfModal 
      :isOpen="isPdfModalOpen" 
      :source-loadable="pdfLoadable" 
      @on-visibility-change="handlePdfModalVisibility"
      :filename="filename"
    />
  </v-container>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import detalle from './components/cmConvenioDetalle.vue'
import { 
  createLoadable, 
  createPageable, 
  toggleLoadable, 
  togglePageable, 
  setLoadableResponse, 
  setPageableResponse,
  PAGINATION_PROPERTY_NAMES,
} from '@/utils/loadable';
import PdfModal from '@/components/PdfModal.vue';
import { getFilenameFromPath } from '@/utils/data';

export default {
  name: "CMConvenioList",
  components: {
    DataTableComponent,
    detalle,
    PdfModal,
  },
  data: () => ({
    pdfLoadable: createLoadable(null),
    e1: 1,
    dialog: false,
    isPdfModalOpen: false,
    id_detalle: null,
    listado: createPageable([], 10),
    headers: [
      {
        text: 'Proveedor',
        value: 'Proveedor.nombre',
        align: 'center'
      },
      {
        text: 'Tipo de convenio',
        value: 'CMTipoConvenio.nombre',
        align: 'center'
      },
      {
        text: 'Fecha de creación',
        value: 'fecha',
        align: 'center'
      },
      {
        text: 'Acciones',
        value: 'acciones',
        align: 'center'
      }
    ],
    proveedor: '',
    activeItem: null,
    filename: '',
  }),
  computed: {
    total() {
      return this.listado.pagination[PAGINATION_PROPERTY_NAMES.COUNT];
    }
  },
  methods: {
    async cargarConvenios(filters) {
      togglePageable(this.listado);
      const { data, headers } = await this.services.cmConvenioMarco.listarConvenios({ nombre: this.proveedor, ...filters });
      setPageableResponse(this.listado, data, headers);
    },
    detalle(id) {
      this.id_detalle = id
      this.dialog = true
    },
    showPdf(item) {
      this.isPdfModalOpen = true;
      this.activeItem = item;
    },
    handlePdfModalVisibility(visible) {
      this.isPdfModalOpen = visible;
    },
    filterName() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        await this.cargarConvenios();
      }, 500);
    },
    async loadPdf(path) {
      toggleLoadable(this.pdfLoadable);
      const { data } = await this.services.cmConvenioMarco.obtenerDocumentoConvenio({
        nombre: path,
      });

      setLoadableResponse(this.pdfLoadable, data, { isFile: true });
    },
    paginar(pagination) {
      const { cantidad_por_pagina, pagina } = pagination;
      const filters = {};
      filters[PAGINATION_PROPERTY_NAMES.CURRENT_PAGE] = pagina;
      filters[PAGINATION_PROPERTY_NAMES.PAGE_SIZE] = cantidad_por_pagina;
      this.cargarConvenios(filters);
    },
  },
  watch: {
    activeItem(value) {
      if (value && value.pdf) {
        this.loadPdf(value.pdf);
        this.filename = getFilenameFromPath(value.pdf);
      } else {
        this.filename = '';
        this.pdfLoadable = createLoadable(null);
      }
    },
  },
  async mounted() {
    await this.cargarConvenios()
  }
}
</script>
