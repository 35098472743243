<template>
  <v-row>
    <v-col md="12" cols="12">
      <v-card>
        <v-card-text v-if="show">
          <institucionRead/>
          <inicioRead/>
          <listadoFirmantes :actions="false"/>
          <listadoDirecciones :actions="false" v-if="getTipoConvenio?.id===2"/>
          <listadoRegiones :actions="false" v-else/>
          <listadoOfertas :actions="false" :detalle="true"/>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cerrar">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import listadoOfertas from "@/views/CMConvenio/components/cmConvenioOfertasListado.vue";
import listadoRegiones from "@/views/CMConvenio/components/cmConvenioRegionesListado.vue";
import institucionRead from "@/views/CMConvenio/components/cmConvenioInstitucionRead.vue";
import listadoDirecciones from "@/views/CMConvenio/components/cmConvenioDireccionesListado.vue";
import inicioRead from "@/views/CMConvenio/components/cmConvenioInicioRead.vue";
import listadoFirmantes from "@/views/CMConvenio/components/cmConvenioFirmantesListado.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CMConvenioDetalle",
  components: {listadoFirmantes, inicioRead, listadoDirecciones, institucionRead, listadoRegiones, listadoOfertas},
  data: () => ({
    e1: 1,
    show: false,
  }),
  computed: {
    ...mapGetters('cmConvenioStore', ['getTipoConvenio']),
  },
  methods: {
    ...mapActions(
        'cmConvenioStore',
        ['setFirmantes', 'setFechaConvenio', 'setTipoConvenio', 'setInstitucion', 'setRegiones', 'setOfertas']
    ),
    async cargarDetalle() {
      const {data, status} = await this.services.cmConvenioMarco.obtenerConvenio(this.id)
      if (status === 200) {
        this.show = true
        const {ofertas_productos, ConvenioMarco} = data
        const {firmantes, fecha, CMTipoConvenio, Proveedor, conMar_regCon} = ConvenioMarco
        this.setFirmantes(firmantes)
        this.setFechaConvenio(fecha)
        this.setTipoConvenio(CMTipoConvenio)
        this.setInstitucion(Proveedor)
        this.setRegiones(conMar_regCon)
        this.setOfertas(ofertas_productos)
      }
    },
    cerrar() {
      this.setFirmantes({
        firmantes_oferente: [],
        firmantes_dinac: []
      })
      this.setFechaConvenio(null)
      this.setTipoConvenio(null)
      this.setInstitucion(null)
      this.setRegiones([])
      this.setOfertas([])
      this.show = false
      this.$emit('cerrar')
    }
  },
  props: {
    id: {
      typeof: Number,
      required: true
    }
  },
  async mounted() {
    await this.cargarDetalle()
  }
}
</script>
